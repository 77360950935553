/* footer-section starts */
.footer-section {
  padding: 154px 20px 40px;
  color: #ffffff;
  position: relative;
  z-index: 999;

  .footer-globe {
    position: absolute;
    height: 450px;
    z-index: 400;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }
  .footer-data {
    background-color: red;
  }

  .footer-content {
    z-index: 1000;
    border-radius: 30px;
    border: 1px solid #fff;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(150px);
    padding: 72px 32px 24px;
    position: relative;

    .footer-container {
      display: flex;
      gap: 0px;

      .navigation-container {
        display: flex;
        flex-wrap: wrap;
        /* grid-gap:0%; */
        justify-content: space-between;
        /* margin-top: 15px; */

        h6 {
          color: #fff;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 16px;
          width: auto;
          padding-top: 15px;
          font-family: Poppins;
        }

        ul {
          list-style: none;
          padding: 0;
          margin-bottom: 0;

          li {
            margin-bottom: 18px;

            &:last-child {
              margin-bottom: 0;
            }

            a {
              color: #fff !important;
              font-size: 16px;
              text-decoration: none;
              font-family: Poppins;

              /* &:hover {
                } */
            }
          }
        }
      }
    }

    .copyright-text {
      color: rgba(255, 255, 255, 0.6);
      font-size: 14px;
      margin-bottom: 0;
      text-align: center;
      margin-top:12px;
      font-family: Poppins;
    }
  }
}
.pinFooter{
  font-family: Poppins;
}

/* footer-section ends */
