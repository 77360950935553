/* Navbar styles */
/* CM Logo */


.navbar-brand {
  margin-left: 2%;
  margin-right: 0%;
  margin-top: 0%;
  margin-bottom: 0%;
}

.nav-btn {
  margin-left: 0% !important;
  margin-right: 0% !important;
  margin-top: 0% !important;
  margin-bottom: 0% !important;
}

.nav-link:hover,
.nav-link.active {
  color: #53b9eb !important;
}

.dropdown-toggle {
  color: #00246a;
}
.dropdown-menu {
  /* background:  #1B9BDC ; */
  color: white;
}

.dropdown-menu a:hover {
  color: #1b9bdc;
}

.nav-link {
  /* margin-left: 10px; */
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 0% !important;
  padding-right: 0% !important;
}

.nav-item-seperator {
  display: none;
}

.header-nav a {
  font-size: 16px;
  color: #ffffff;
  font-family: Poppins;
}

.nav-item {
  font-size: 16px;
  color: #ffffff;
  font-family: Poppins;
  gap: 40px;
  color: #1b9bdc;
  /* margin-left: 60px; */
  /* font-weight: 600; */
}
.nav-item .dropdown {
  font-size: 16px;
  color: #ffffff;
  font-family: Poppins;
  gap: 40px;
  color: #1b9bdc;
  margin-left: 50px;

  margin-left: 0px;
}

/* .mr-auto {
} */

.dropdown-item {
  font-family: Poppins;
  /* color:#0072AD; */
}
/*fixed navbar*/
.navbar {
  /* display: -moz-inline-box; */
  position: fixed;
  top: 0;
  width: 100%;
  /* background: rgba(255, 255, 255, 0.8);  */
  transition: background 0.3s ease;
  z-index: 1000;
  height: 60px;
  display: flex; 
  padding-left: 1%; 
  padding-right:1%
}

.navbar .navbar-toggler:focus,
.navbar .navbar-toggler:active {
  outline: none;
  box-shadow: none;
}

.navbar-nav {
    width: 100%;
    justify-content: space-around;
}

.header-nav .navbar-nav {
  /* gap: 40px; */
  .brand-seconday {
    margin: 0px;
  }
}

.header-nav a.active {
  color: #1b9bdc;
  font-weight: 600;
}
.CallImg{
  height: 20px;
  width: 20px;
  margin-right: 1vw;
}
.info{
  margin-top: -20px;
  font-size: 10px;
  justify-content: space-around;
  font-family: Poppins;
  text-align: right;
  font-weight:500;
  /* margin-top: 0px;ss  */
}
/* .info:hover{
  color:#8159ff;
} */
.timeStamp{
  font-size: 9px;
  font-family: Poppins;
  font-weight:300;
}
/* hamburger kicks in */
@media (max-width: 991px) {
  .navbar-container {
    /* background-color: #172631; */
    background: linear-gradient(
      90deg,
      rgba(31, 32, 37, 1) 0%,
      rgba(2, 0, 36, 1) 35%,
      rgba(7, 68, 100, 1) 100%
    );
  }

  .nav-item-container {
    font-size: 1.23rem;
    margin-left: 4.5%;
  }

  .nav-item-seperator {
    display: block;
    margin-left: 4.5%;
    margin-right: 4.5%;
  }

  .nav-btn {
    margin-top: 2% !important;
    margin-bottom: 1% !important;
    align-self: center;
    width:91%;
  }


}

/* phone view */
@media only screen and (max-width: 767px) {
  .info{text-align: center;margin-top: -10px;}
  .header-nav a {
    font-size: 14px;
  }
}

/*styling is not working ,we have to media query*/
/* @media only screen and (max-width: 1279px) {
  .nav-item {
    margin-left: 40px;
  }
}
@media only screen and (max-width: 1219px) {
  .nav-item {
    margin-left: 35px;
  }
}
@media only screen and (max-width: 1190px) {
  .nav-item {
    margin-left: 30px;
  }
} */

@media only screen and (max-width: 1160px) {
  /* .nav-item {
      margin-left: 25px;
    }
    @media only screen and (max-width: 1130px) {
      .nav-item {
        margin-left: 20px;
      }
    } */
  /* @media only screen and (max-width: 1100px) {
      .nav-item {
        margin-left: 15px;
      }
    }
    @media only screen and (max-width: 1070px) {
      .nav-item {
        margin-left: 10px;
      }
    }
    @media only screen and (max-width: 1040px) {
      .nav-item {
        margin-left: 5px;
      }
    }
    @media only screen and (max-width: 1010px) {
      .nav-item {
        margin-left: 0px;
      }
    } */
}
