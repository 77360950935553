/* Number ke styles */

.no-spinner::-webkit-outer-spin-button,
.no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinner {
  -moz-appearance: textfield;
}

.uniform-width {
  width: 100px; /* Set your desired width */
}

.border-subtle-blue {
  border: 1px solid #7ec5e8;
  color: rgb(27, 155, 220);
  padding: 6px 10px;
  border-radius: 5px;
  font-size: 16px;
  background-color: #f0f8ff;
}

.border-subtle-blue:focus {
  outline: none; /* Removes the default focus outline */
  border-color: #55c6ff; /* Changes the border color on focus */
  box-shadow: 0 0 5px rgba(27, 155, 220, 0.5); /* Adds a subtle glow effect */
}

/* Styles End */

/* infographic start */
/* .info-trigger {
  cursor: pointer;
  margin-left: 5px;
} */

/* .loan-infographic {
  display: none;
  position: absolute;
  width: 200px; 
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  z-index: 100; 
} */

/* .info-trigger:hover + .loan-infographic {
  display: block;
} */
/* infographic end */

h3 {
  margin-top: 60px;
  background: linear-gradient(
    180deg,
    rgb(27, 155, 220) 0%,
    rgb(117.94, 208.84, 255) 54.51%,
    rgb(27, 155, 220) 100%
  );
  background-clip: text;
  color: transparent;
  font-size: 45px;
  font-weight: 600;
  margin-bottom: 1rem;
  line-height: normal;
  text-align: center;
  font-family: Poppins;
}

.page-container.calculator {
  padding: 200px;
}

.row.main-slider {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-range-slider {
  margin-top: 0px;
  width: 100%;
  -webkit-appearance: none;

  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.form-range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #199cdb;
  cursor: pointer;
}

.form-range-slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #199cdb;
  cursor: pointer;
}

.mt-7 {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.753);
  border: 1px solid #fff;
  width: 10vw;
  height: 5vh;
  margin-left: 14vw;
}

.mb-4 {
  margin: 20px;
}

.slider-container {
  padding: 0 15px;
  border: 1px solid #ddd;
  border-radius: 30px;
  background-color: #f9f9f9;
  margin: 20px;
  display: flex;
  flex-direction: column;
  height: 79%;
  justify-content: space-evenly;
}

.input-section {
  margin-bottom: 3% !important;
}

.text-and-input {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
}

.headers {
  color: #1b9bdc;
  margin-bottom: 0;
  margin-right: 10px;
  font-size: 20px;
  font-weight: 500;
  font-family: Poppins;
}

.input-wrapper {
  position: relative;
  display: inline-block;
}

.input-wrapper::after {
  content: "%";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #199cdb;
}

.input-wrapper-principle {
  position: relative;
  display: inline-block;
}
.input-wrapper-principle::after {
  content: "₹";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #199cdb;
}
.form-cc {
  padding-right: 10px; /* Adjust to make space for the symbol */
}
.applyNowContainerMobile {
  display: none;
}

.applyNow {
  width: 100%;
  padding: 10px;
  font-size: 1.25rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  width: 60%;
}

.applyNow:hover {
  background-color: #0056b3;
}

.pie-chart-container {
  margin-top: 4.8%;
  margin-bottom: 8%;
  overflow: visible;
}

.pie {
  width: 45%;
  overflow: visible;
}

.calculator-card {
  border-radius: 32px;
  background: linear-gradient(140deg, #5e7784 5.94%, #151b1e 90.73%);
  color: #ffffff;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5%;
  margin-right: 5%;
  padding-top: 1%;
  padding-bottom: 0.7%;
  border: 1px solid #3caff5;
  /* margin: 50px;
    padding: 150px 50px; */
}

.applyNow {
  border-radius: 0px !important;
  border: 1px solid #fff;
  background: linear-gradient(91deg, #3caff5 -2.45%, #1a69a3 97.01%);
  color: #fff;
  /* font-weight: 700; */
  border-image-source: linear-gradient(to left, #1b9bdc, #a9e2ff, #1b9bdc);
  border-width: 1px;
  border-image-slice: 1;
  border-image-width: 1;
  border-image-outset: 0;
  border-image-repeat: round;
  font-size: 18px;
  font-family: Poppins;
  padding: 6px;
  /* margin: 30%; */
  /* margin-left:230px */
}

.text-header {
  background: linear-gradient(
    180deg,
    rgb(27, 155, 220) 0%,
    rgb(117.94, 208.84, 255) 54.51%,
    rgb(27, 155, 220) 100%
  );
  background-clip: text;
  color: transparent;
  font-size: 45px;
  font-weight: 600;
  line-height: normal;
  font-family: Poppins;
  width: 100%;
  text-align: left;
}

.emi-details-container{
  
}

.colors {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 3%;
  padding-bottom: 3%;

  height: 10px;
  font-size: 16px;
  /* margin: 20px; */
  color: #fff;
  font-weight: 400;
  .legendText {
    font-size: 1rem;
    display: inline-flex;
    /* transform: translateY(-40%); */
  }
}

.lightBlue {
  border-left: 10px solid #199cdb;
  height: 10px;
  font-size: 18px;
  /* margin: 10px 10px; */
  margin-right: 10px;

  color: #fff;
  font-weight: 400;
  display: inline-flex;
}

.whiteBox {
  border-left: 10px solid #ffff;
  height: 10px;
  font-size: 18px;
  /* margin: 10px 10px; */
  margin-right: 10px;
  color: #fff;
  font-weight: 400;
  display: inline-flex;
}

.purpleBox {
  border-left: 10px solid #6610f2;
  height: 10px;
  font-size: 18px;
  margin: 10px 10px;
  color: #fff;
  font-weight: 400;
}
.interestAndPay {
  display: flex;
  justify-content: center;
}
.vlCalci {
  /* border-left: 2px solid #052c65; */
  font-size: 18px;
  margin: auto;
}
.aboutEmi {
  text-align: justify;
  font-size: 18px;
  margin-bottom: 0px;
  font-weight: 300;
  color: #fff;
  font-family: Poppins;
}
.EmiElements {
  font-size: 18px;
  margin-bottom: 0px;
  font-weight: 300;
  color: #fff;
  font-family: Poppins;
}
.emi-details {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: flex-start;
  margin-top: 30px;
  font-size: 1.4rem;
  padding-left: 2%;
  padding-right: 2%;
}

.emi-left {
  flex: 6.5;
  text-align: left;
  /* Additional styles for left column if needed */
}

.emi-right {
  flex: 2.3;
  text-align: left;
  color: #fff;
  /* Additional styles for right column if needed */
}

.glow {
  box-shadow: 0 0 13px rgba(79, 255, 255, 0.44); /* Example glow effect */
  transition: box-shadow 0.5s ease-in-out;
}
