@media (max-width: 760px) {
  .footer-globe {
    height: 300px !important;
    top: 45px !important;
    /* margin-top: 10%;
    width: 80%; */
  }

  .pinFooter {
    margin-left: 0px;
  }

  .footer-content {
    flex-direction: column;
    font-size: 14px;
  }

  .footer-content .navigation-container {
    flex-direction: column;
  }
}
