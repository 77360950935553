@media (max-width: 768px) {
  .loan-calc-heading {
    font-size: 2.1rem;
  }
  .calculator-card {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 0;
  }
  .colors {
    display: none;
  }
  .colors {
    justify-content: center;
  }
  .output-container {
    h5 {
      margin-left: 0px;
    }
    margin-bottom: 5%;
  }
  .slider-container {
    padding: 0px;
    height: 90%;
    .headers {
      font-size: 1rem;
    }
    .form-range-slider {
      width: 100%;
    }
  }

  .form-cc {
    width: 22vw;
    font-size: 16px;
  }
  .pie {
    height: 30vh;
    width: 60vw;
    display: none;
  }

  .emi-details {
    padding-left: 5%;
    padding-right: 5%;
    span {
      font-size: 1rem;
    }
    .emi-left {
      flex: 6;
    }
    .emi-right {
      flex: 5;
      text-align: right;
    }
  }
  .legendText {
    font-size: 1rem;
  }

  .applyNowContainerLaptop {
    display: none !important;
  }
  .applyNowContainerMobile {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }

  .aboutEmi {
    padding-left: 2.8%;
    padding-right: 2.8%;
  }
  .col-sm-5 {
    display: block !important;
    width: 100%; /* Makes the element take the full width */
  }
  .col-sm-6 {
    display: block !important;
    width: 100%; /* Makes the element take the full width */
  }
  .pie-chart-container {
    margin: 0px;
  }
}