@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap");

/* .search-input{
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.08) 56.4%, rgba(153, 153, 153, 0.04) 100%);
  
  width: 100%;
  height: 64px;
  color: #ffffff;
  border: 1px solid #FFF;
  padding:10px;
text-align: center;  


} */
/* li {
  margin-bottom: 16px;
  color: #fff;
  font-weight: 400;
  font-family: Poppins;
  font-size: 1rem;
} */
.th-products {
  margin-bottom: 12px;
  color: #fff;
  font-weight: 400;
  font-family: Poppins;
}
.th-products-header {
  margin-bottom: 16px;
  color: #fff;
  font-weight: bold;
  font-family: Poppins;
}
.th-products-desc {
  margin-bottom: 12px;
  color: #fff;
  font-weight: 400;
  font-family: Poppins;
  /* width:100vw; */
}
.table-data-products {
  color: #fff;
  font-size: 18px;
  font-family: Poppins;
}
.slide-in-left {
  animation: slideInLeft 5s ease;
}

.slide-in-right {
  animation: slideInRight 5s ease;
}

@keyframes slideInLeft {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
/* ResponsibleLending.css */
@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.resLending {
  opacity: 0;
  transition: opacity 0.25s, transform 2s;
}

.resLending.animate {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  animation: fadeInUp 1s forwards;
}

.hero-heading {
  margin: 32px 0;
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  margin-top: -80px;
  font-family: Poppins;
}
.hero-sub {
  margin: 32px 0;
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  margin-top: -40px;
  font-family: Poppins;
  margin-top: 120px;
}

.App {
  overflow: hidden;
}

.row {
  justify-content: space-evenly;
}

.App {
  background: rgb(31, 32, 37);
  background: linear-gradient(
    90deg,
    rgba(31, 32, 37, 1) 0%,
    rgba(2, 0, 36, 1) 35%,
    rgba(7, 68, 100, 1) 100%
  );
}

.page-container {
  padding: 0 88px 110px;

  &.products-page {
    padding-right: 100px;
    padding-left: 100px;
    padding-top: 40px;
  }

  &.responsible-lending-page {
    padding-right: 136px;
    padding-left: 136px;
    padding-top: 115px;
  }

  &.contact-us {
    padding-top: 80px;
    padding-right: 200px;
    padding-left: 200px;
  }

  &.about-us {
    padding-top: 80px;
    padding-right: 80px;
    padding-left: 80px;
  }

  &.lending-partners {
    padding-top: 87px;
    padding-right: 3%;
    padding-left: 3%;
  }

  &.faq {
    padding-top: 96px;
    /* padding-right: 336px;
    padding-left: 336px; */
  }
}

.b {
  margin-top: 20px;
}

/* hero section starts */

.home-download {
  display: none;
}
.home-apply {
  width: 30%;
}

.brand-secondary {
  margin: 35px;
}

.hero-section {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-top: 100px;
  padding-bottom: 160px;
}

.hero-section img {
  margin-top: 70px;
  height: 400px;
  position: absolute;
  align-items: right;
  right: 20px;
  top: 40px;
}
@keyframes vertical-shake {
  0%,
  100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
}

.img-home {
  animation: vertical-shake 4s infinite;
}

.hero-section .content {
  width: 70%;
}

.hero-section .content h6 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    180deg,
    rgb(27, 155, 220) 0%,
    rgb(117.94, 208.84, 255) 54.51%,
    rgb(27, 155, 220) 100%
  );
  background-clip: text;
  color: transparent;
  font-size: 50px;
  font-weight: 800;
  margin-bottom: 1rem;
  line-height: normal;
  font-family: Poppins;
  /* font-family: "General Sans Variable"; */
}

.hero-section .content p {
  /* color: rgba(255, 255, 255, 0.6); */
  color: #dee2e6;
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 24px;
  /* width: 700px; */
  font-family: Poppins;
}

/* hero section ends */

/* application-process-section starts */
.halfcard {
  padding: 15px;
}

.application-process-card {
  /* margin: 0px; */
  display: flex;
  justify-content: space-between;
  padding: 10px 0 20px 80px;
  border-radius: 24px;
  background: linear-gradient(140deg, #5e7784 5.94%, #151b1e 90.73%);
  color: #ffffff;

  .content {
    width: 100%;

    .list-number {
      display: flex;
      margin-top: 24px;
      height: 46px;
      width: 46px;
      border-radius: 13px;
      background: #0072ad;
      line-height: 28px;
      align-items: center;
      justify-content: center;

      /* it gets ruined in prod so switching to light-overlay-number */
      span {
        width: 36px;
        height: 36px;
        display: flex;
        background: rgba(255, 255, 255, 0.4);
        border-radius: 40px;
        font-size: 24px;
        justify-content: center;
        align-items: center;
      }

      .light-overlay-number {
        background: rgba(255, 255, 255, 0.4);
        border-radius: 40px;
        font-size: 24px;
        padding-left: 25%;
        padding-right: 25%;
        padding-top: 10%;
        padding-bottom: 10%;
        width: 36px;
        height: 36px;
      }
    }

    h6 {
      margin-top: 30px;
      text-align: left;
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 16px;
      font-family: Poppins;
    }

    p {
      text-align: left;
      font-size: 18px;
      margin-bottom: 0px;
      font-weight: 300;
      font-family: Poppins;
    }
  }

  & + .application-process-card {
    margin-top: 64px;
  }

  &.small-card {
    padding: 0 0 0 80px;
    margin-bottom: 32px;

    h6 {
      margin-top: 40px;
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 32px;
    }

    &.half-card {
      padding-top: 0px;
      padding-bottom: 54px;
      padding-right: 40px;
      display: flex;
      align-items: end;

      .content {
        width: calc(100% - 104px + 24px);
        margin-right: 24px;
      }
    }
  }
}

.application-process-section {
  .heading {
    margin: 32px 0;
    color: #fff;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    margin-top: -40px;
    font-family: Poppins;

    &.extra-margin-top {
      margin-top: 160px;
      font-family: Poppins;
      font-size: 36px;
    }
  }
}

/* application-process-section ends */

/* user-feedback-section starts */
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

.user-feedback-section {
  margin-top: 160px;

  .section-heading {
    margin-bottom: 48px;
    margin-top: -80px;
    font-family: Poppins;
  }

  .feedback-card-container {
    display: flex;
    gap: 32px;
    align-items: center;
    justify-content: center;

    .each-feedback-card {
      padding: 10px;
      border-radius: 30px;
      /* display: flex; */
      align-items: center;
      background: linear-gradient(140deg, #5e7784 5.94%, #151b1e 90.73%);
      /* padding: 34px 64px; */

      .card-body {
        padding: 0;
        text-align: center;

        img {
          margin-bottom: 16px;
        }

        .feedback-ratings {
          height: 34px;
          margin-bottom: 22px;
        }

        .comment {
          color: #fff;
          font-size: 16px;
          margin-bottom: 48px;
        }

        .user-name {
          color: rgba(255, 255, 255, 0.6);
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 0;
        }
      }
    }
  }
}
.text-center {
  margin-top: 40px;
  width: 300px;
}

.animate-left {
  animation: slideInFromLeft 1s ease-out forwards;
}

.animate-right {
  animation: slideInFromRight 1s ease-out forwards;
}

.animate-shake {
  animation: shake 0.5s ease-in-out 1s forwards;
}

/* user-feedback-section ends */

/* products-hero-section starts */
.products-hero-section,
.responsible-lending-section,
.contact-us-section,
.faq-section {
  h6,
  .heading {
    background: linear-gradient(
      91deg,
      #1b9bdc -2.45%,
      #76d1ff 51.77%,
      #1b9bdc 97.01%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 45px;
    font-weight: 700;
    text-align: center;
    font-family: Poppins;
    margin: 50px;
  }
}

.uncolored-heading {
  color: white;
  background-color: transparent;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  font-family: Poppins;
  margin: 50px;
}

.products-hero-section {
  padding-bottom: 50px; /* padding-bottom: 230px;  */
  text-align: center;

  small {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 44px;
    display: block;
    font-family: Poppins;
  }

  p {
    color: #fff;
    font-size: 18px;
    margin-bottom: 0;
    font-family: Poppins;
    margin-bottom: -50px;
  }

  .btn-container {
    margin-top: 128px;

    .primary-gradient-btn {
      border-radius: 8px;
      border: 1px solid #fff;
      background: var(
        --card-gradient,
        linear-gradient(140deg, #5e7784 5.94%, #151b1e 90.73%)
      );
      display: flex;
      width: 324px;
      height: 64px;
      padding: 20px;
      align-items: center;
      gap: 16px;
      color: #ffffff;
      font-family: Poppins;
    }
  }
}
.eligibility {
  margin-bottom: -60px;
  margin-top: 50px;
}

/* products-hero-section ends */

/* description-section starts */
.description-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 120px; */
  padding: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  .content {
    color: #fff;

    h6 {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 44px;
      font-family: Poppins;
    }

    ol {
      font-size: 24px;
      padding-left: 28px;

      li {
        margin-bottom: 16px;
        color: #fff;
        font-weight: 400;
        font-family: Poppins;
        font-size: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

/* description-section ends */

/* responsible-lending-section starts */
.responsible-lending-section,
.contact-us-section {
  .content {
    p {
      margin-top: 32px;
      color: #fff;
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 0;
      font-family: Poppins;
    }
  }
}

.responsible-lending-section {
  text-align: center;

  .content {
    margin-bottom: 100px;

    p {
      margin-top: 24px;
      color: #fff;
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 0;
    }
  }

  .section-heading {
    color: #fff;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 64px;
    text-align: center;
    font-family: Poppins;
  }

  .application-process-card {
    padding: 50px 36px;

    .content {
      margin: 0;
      width: 100%;
      padding-left: 64px;
      position: relative;
      text-align: left;

      p {
        margin-top: 0;
        font-size: 24px;
      }

      .list-number {
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 0px;
      }
    }

    &.application-process-card {
      margin-top: 40px;
      height: 200px;
      display: flex;
      align-items: center;
    }
  }
}

/* responsible-lending-section ends */

/* contact-us-section starts */
.contact-us-section {
  /* text-align: center; */

  .heading {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 54px;
    font-weight: 700;
    font-family: Poppins;
  }

  .content {
    margin-bottom: 180px;
  }
}

.form-control-dd-options {
  padding: 10px;
  color: #151b1e;
}
.dropdown {
}

.contact-submit-button {
  background-color: #0bb2e6;
  color: white;
  /* padding: 15px 20px; */
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
  width: auto;
  margin-left: 38%;
  font-family: Poppins;
}

@media (min-width:768px) {
  .cntUs{
    display: flex;
    padding: 0 58px 110px;
  /* justify-content:safe; */
  }
}

/* contact-us-section ends */

/* about-us-container starts */
.about-us-container {
  .content {
    padding: 0 20px;
    justify-content: center;

    .heading {
      opacity: 0.8;
      color: #1b9bdc;
      text-align: center;
      font-size: 48px;
      font-weight: 700;
      margin-bottom: 52px;
      font-family: Poppins;
      margin-top: 50px;
    }

    .about-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 120px;

      .about-section-content {
        margin-top: 60px;
        padding-right: 6%;

        h6 {
          color: #fff;
          font-size: 22px;
          font-weight: 500;
          margin-bottom: 12px;
          font-family: Poppins;
        }

        p {
          color: #fff;
          margin-left: 0%;
          font-size: 16px;
          font-weight: 300;
          margin-bottom: 0;
          font-family: Poppins;
        }
      }
    }
  }
  .aboutus {
    padding-top: 6%;
  }
  @media (max-width: 768px) {
    .aboutus {
      margin: 20px;
    }
  }
  .about-points-container {
    margin-bottom: 160px;

    .each-point {
      /* &:nth-child(even) {
        padding-left: 20px;
      } */

      /* &:nth-child(odd) {
        padding-right: 20px;
      } */

      .quote-container {
        padding: 0 16px;
        display: flex;

        span {
          opacity: 0.99;
          background: linear-gradient(91deg, #4cbffc -2.45%, #2199ef 97.01%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 80px;
          font-weight: 700;
        }

        p {
          color: #7ed2ff;
          font-size: 22px;
          font-weight: 700;
          opacity: 0.8;
          margin-bottom: 0;
          padding: 30px 0;
          display: flex;
          align-items: center;
        }
      }

      .application-process-card {
        padding: 22px;
        height: 220px;

        .content {
          width: 100%;
          padding-bottom: 5%;
          padding-left: 0%;
          padding-right: 0%;

          p {
            padding: 0%;
            font-size: 16px;
          }
        }
      }
    }
  }

  .application-process-card {
    align-items: center;
    padding-left: 1%;
    padding-top: 2%;

    h6 {
      color: #fff;
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 8px;
      margin-top: 0;
      font-family: Poppins;
    }

    p {
      color: #fff;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 0;
      font-family: Poppins;
    }
  }
}

/* about-us-container ends */

/* lendingpartners-section starts */
.legend-partners{
    border-radius: 32px;
    background: linear-gradient(140deg, #5e7784 5.94%, #151b1e 90.73%);
    color: #ffffff;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 5%;
    margin-right: 5%;
    padding-top: 1%;
    padding-bottom: 0.7%;
    border: 1px solid #3caff5;
    /* margin: 50px;
      padding: 150px 50px; */
    
}
.lendingpartners-section {
  .content {
    text-align: center;

    h6.heading {
      background: linear-gradient(90deg, #1b9bdc 0%, #7ed2ff 53%, #1b9bdc 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 54px;
      font-weight: 700;
      margin-bottom: 36px;
    }

    p.caption {
      color: #fff;
      font-size: 20px;
      margin-bottom: 50px;
    }

    .company-container {
      h6 {
        margin-top: 16px;
        color: #fff;
        font-size: 40px;
        font-weight: 700;
        letter-spacing: 2.4px;
        margin-bottom: 64px;
      }

      p {
        color: #fff;
        font-size: 40px;
        font-weight: 500;
        letter-spacing: 2px;
        margin-bottom: 80px;
      }

      .website-text {
        color: #fff;
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 1.6px;
        font-family: Poppins;
      }
    }
  }
}

/* lendingpartners-section ends */

/* faq-section starts */
.faq-section {
  .faq-container {
    margin-top: 96px;
    max-width: 768px;

    .form-group {
      position: relative;
      margin-bottom: 48px;

      span {
        position: absolute;
        left: 24px;
        top: 28px;
      }

      input {
        padding: 24px;
        padding-left: 68px;
        border-radius: 8px;
        border: 1px solid #d3d3d3;
        color: #ffffff;
        font-size: 24px;
        height: 88px;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0.08) 56.4%,
          rgba(153, 153, 153, 0.04) 100%
        );
        box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.12);

        &::-ms-input-placeholder {
          /* Edge 12-18 */
          color: #fff;
        }

        &::placeholder {
          color: #fff;
        }
      }
    }

    .faq-tab-container {
      .tab-buttons {
        button {
          width: 100%;
          height: 64px;
          color: #ffffff;
          border: 1px solid #fff;
          /* margin: 0px; */

          &.active-btn {
            background: linear-gradient(91deg, #00246a -2.45%, #2199ef 97.01%);
          }
        }
      }
      .answer-section {
        margin-top: 48px;
        width: 100%;

        .each-answer {
          margin-bottom: 32px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .faq-toggler {
          width: 100%;
          border: 1px solid #ffffff;
          padding: 0;

          .faq-toggler-content {
            display: flex;
            width: 100%;
            padding: 14px;
            justify-content: space-between;
            align-items: center;
            color: #fff;
            font-size: 14px;
            font-weight: 500;
            line-height: normal;
            text-align: left;
            font-family: Poppins;

            svg {
              &.isopened {
                animation-direction: normal;
                animation-duration: 0.5s;
                animation-iteration-count: 1;
                animation-name: rotateBottomhalf;
                transform: rotate(180deg) !important;
              }
            }
          }

          p {
            margin-bottom: 0;
            color: #ffffff;
            font-size: 14px;
            text-align: left;
            padding: 14px;
            padding-top: 0;
            font-family: Poppins;
          }
        }
      }
    }
    .faqBottomHeaderLine {
      font-size: 28px;
      font-family: Poppins;
    }

    .faqBottomLine {
      font-size: 22px;
      font-family: Poppins;
      font-weight: 500;
    }
    .more-questions {
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 80px;

      p {
        font-size: 40px;
        font-weight: 600;
        margin-bottom: 24px;
      }

      span {
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 24px;
      }

      a {
        width: 222px;
        height: 64px;
        padding: 10px;
        border-radius: 8px;
        border: 1px solid #fff;
        color: #fff;
        font-size: 24px;
        font-weight: 700;
        background: linear-gradient(91deg, #3caff5 -2.45%, #1a69a3 97.01%);
        text-align: center;
      }
    }
  }
}

@keyframes rotateBottomhalf {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(90deg);
  }
}
@keyframes slide-in-bottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* faq-section ends */

/* common styling starts */

.section-heading {
  margin: 32px 0;
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
}

.brand-secondary {
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  font-family: Poppins;
  border-image-source: linear-gradient(to left, #1b9bdc, #a9e2ff, #1b9bdc);
  border-width: 1px;
  border-image-slice: 1;
  border-image-width: 1;
  border-image-outset: 0;
  border-image-repeat: round;
}

.brand-secondary.lg {
  padding: 8px 40px;
}

.brand-primary {
  /* border: 1px solid #198bc7; */
  border-radius: 4px;
  background: #198bc7;
  color: #ffffff;
  font-family: Poppins;
  border-image-source: linear-gradient(to left, #1b9bdc, #a9e2ff, #1b9bdc);
  border-width: 1px;
  border-image-slice: 1;
  border-image-width: 1;
  border-image-outset: 0;
  border-image-repeat: round;
}

.brand-primary.lg {
  padding: 8px 40px;
}

.brand-primary-gradient {
  border-radius: 0px !important;
  border: 1px solid #fff;
  background: linear-gradient(91deg, #3caff5 -2.45%, #1a69a3 97.01%);
  color: #fff;
  font-weight: 700;
  height: 60px;
  font-family: Poppins;

  &.lg-btn {
    font-size: 24px;
  }
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.website-text {
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 1.6px;
}

.custom-background {
  /* background: rgb(31, 32, 37); */
  background: linear-gradient(
    90deg,
    rgba(31, 32, 37, 1) 0%,
    rgba(2, 0, 36, 1) 35%,
    rgba(7, 68, 100, 1) 100%
  );
}

.inmageHome {
  height: 300px;
  margin: auto;
}

.inmage {
  margin-right: 20px;
  margin-block: auto;
}

.applpcardbuttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.needsContent {
  /* margin-right: 100px; */
  margin-top: -15px;
}

.intext {
  margin-left: -50px;
  width: 100%; /* width: 400px; */
  height: 100%;
}
.rounded-circle {
  align-self: center;
}

.text-center {
  background: linear-gradient(
    180deg,
    rgb(27, 155, 220) 0%,
    rgb(117.94, 208.84, 255) 54.51%,
    rgb(27, 155, 220) 100%
  );
  background-clip: text;
  color: transparent;
  font-size: 45px;
  font-weight: 600;
  line-height: normal;
  font-family: Poppins;
  width: 100%;
}

.main {
  padding: 6rem;
}

.productsDiv {
  padding: 10px;
}

/* .tnc {
  margin-left: 5%;
  width: 90%;
} */
.productHeader {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    180deg,
    rgb(27, 155, 220) 0%,
    rgb(117.94, 208.84, 255) 54.51%,
    rgb(27, 155, 220) 100%
  );
  background-clip: text;
  color: transparent;
  font-size: 50px;
  font-weight: 800;
  margin-bottom: 1rem;
  line-height: normal;
  font-family: Poppins;
  margin: 20px;
}

@media only screen and (max-width: 767px) {
  .mainDiv {
    display: grid;
    justify-items: center;
  }

  .hero-heading {
    margin-top: 20px;
  }
  .text-center {
    font-size: 30px;
  }
  .inmage {
    padding: 5%;
  }
  .main {
    /* margin:-40px; */
    display: block;
    /* padding: 3rem; */
    width: 100%;
  }
  /* tnc {
    width: 100%;
    text-align: center;
    margin-left: 3%;
    padding-right: 5%; 
  } */
  /* .text-center{
   width:120%;
    text-align:-webkit-match-parent;
    padding-right: 30%;
  } */

  .img-fluid {
    align-self: center;
    max-width: 80%;
  }
  .feedback-ratings {
    padding: 0%;
  }
  .applpcardbuttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .intext {
    margin-left: 10px;
    width: 200px;
    text-align: center;
  }

  .application-process-section {
    .heading {
      margin-top: 30px;
      /* how it works */
    }
  }

  .a {
    margin-top: 430px;
    margin-left: -40px;
  }

  .about-us-container {
    & .content {
      padding-left: 0px;
      padding-right: 0px;
      margin-left: 0px;
      .heading {
        padding-top: 10%;
        justify-content: baseline;
        opacity: 0.8;
        color: #1b9bdc;
        text-align: center;
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 72px;
      }
    }
  }

  .application-process-card {
    /* align-content: center; */
    padding: 15px;
  }
  .lending {
    /* width: 400px; */
    display: flex;
    align-content: stretch;
    justify-content: space-evenly;
    margin-top: -30px;
  }

  .space {
    height: 20px;
  }

  .page-container {
    padding: 0 20px 60px;
    margin-top: -5px;
  }

  .application-process-card {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .user-feedback-section .feedback-card-container {
    flex-direction: column;
    align-items: center;
    padding-right: 10%;
    padding-left: 10%;
  }

  /* img{
    display: none;
  } */

  .page-container {
    padding: 0 20px 60px;

    &.products-page {
      padding-right: 20px;
      padding-left: 20px;
      padding-top: 86px;
    }
  }

  .App {
    overflow: hidden;
  }

  .products-hero-section {
    padding-bottom: 100px;
  }

  .description-section {
    margin-top: -20px;
    margin-bottom: 0px;
    display: block;
  }

  .btn {
    width: 98%;
    margin-bottom: 10px;
    /* margin-left:-15px */
  }

  /* .produc {
    gap: 0px
  } */

  .heading {
    padding: 0px;
  }

  .img-home {
    padding-bottom: 50px;
    margin-right: 20%;
  }
  .a {
    margin-top: 430px;
  }

  .brand-primary {
    margin-left: 3%;
    margin-right: 3%;
  }

  .brand-secondary {
    margin-left: 3%;
    margin-right: 3%;
  }

  .end {
    margin-left: -5px;
  }

  .hero-content {
    text-align: left;
  }

  .hero-content-button {
    width: 150%;
  }

  .hero-section .content h6 {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(
      180deg,
      rgb(27, 155, 220) 0%,
      rgb(117.94, 208.84, 255) 54.51%,
      rgb(27, 155, 220) 100%
    );
    background-clip: text;
    color: transparent;
    font-size: 21px;
    font-weight: 800;
    /* font-size: 20px; */
    /* font-weight: 600; */
    margin-bottom: 1rem;
    line-height: normal;
    width: 300px;
    margin-top: 50px;
    /* font-family: "General Sans Variable"; */
  }
  .hero-section .content p {
    font-size: 18px;
    font-weight: 700;
    width: 300px;
  }

  p {
    margin-left: left;
  }

  .hero-section .content {
    width: 205px;
  }

  .application-process-card {
    display: flex;
    justify-content: space-between;
    padding: 60px 0 0px 80px;
    border-radius: 24px;
    background: linear-gradient(140deg, #5e7784 5.94%, #151b1e 90.73%);
    color: #ffffff;

    .content {
      width: 125%;
      align-self: center;
      margin-right: 80px;

      .list-number {
        display: flex;
        /* margin-top: 24px; */
        height: 46px;
        width: 46px;
        /* border-radius: 13px; */
        background: #0072ad;
        /* line-height: 28px; */
        align-items: center;
        justify-content: center;

        span {
          width: 36px;
          height: 36px;
          display: block;
          background: rgba(255, 255, 255, 0.4);
          border-radius: 40px;
          font-size: 24px;
          justify-content: center;
          align-items: center;
        }
      }

      h6 {
        margin: 10px;
        padding: 15px;
        margin-top: 12px;
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 16px;
        font-family: Poppins;
      }

      p {
        font-size: 12px;
        margin-bottom: 0px;
        font-weight: 300;
        font-family: Poppins;
      }
    }
  }

  .btn-container {
    margin-left: -20px;
  }

  .application-process-section {
    column-gap: 0px;
    margin: 0px;

    /* .heading {
      &.extra-margin-top {
        margin-top: 24px;
      }
    } */
  }

  /* About Us */
  .aboutcard {
    /* margin-left: 2%;
    margin-right: 2%; */
    .content {
      padding-left: 3%;
      padding-right: 3%;

      /* padding-top: 4%; */
      h6 {
        font-size: 19px !important;
        font-weight: 700 !important;
        margin: 0px;
        padding: 0px;
      }
      p {
        font-size: 16px !important;
        padding: 0px !important;
      }
    }
  }

  .page-container {
    &.about-us {
      padding-top: 0px;
      padding-right: 10px !important;
      padding-left: 10px !important;
    }
  }

  .about-us-container {
    & .about-points-container {
      & .each-point {
        & .application-process-card {
          & .content {
            p {
              padding: 0%;
              font-size: 14px !important;
            }
          }
        }
      }
    }
  }

  .about-points-container {
    .each-point {
      .application-process-card {
        height: 210px !important;
      }
    }
  }

  .about-us-container {
    & .content {
      margin-left: 20px;

      .about-section {
        display: block;
        direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 50px;
      }
    }
  }
  .about-section-content {
    padding-right: 0% !important;
  }

  .products-hero-section,
  .responsible-lending-section,
  .contact-us-section,
  .faq-section {
    height: fit-content;
    width: auto;

    h6,
    .heading {
      background: linear-gradient(
        91deg,
        #1b9bdc -2.45%,
        #76d1ff 51.77%,
        #1b9bdc 97.01%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      margin-top: 25%;
    }

    .section-heading {
      color: #fff;
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 64px;
      font-family: Poppins;
      margin-top: -80px;
    }
  }

  .hero-section {
    padding: 0px;
    margin: 0px;
    height: fit-content;
    margin-left: 30px;
  }

  .page-container {
    &.contact-us {
      padding-top: 0px;
      padding-right: 0px;
      padding-left: 0px;
      font-size: 16px;
    }
  }

  .faq-section {
    & .faq-container {
      & .more-questions {
        p {
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 24px;
        }

        span {
          color: #fff;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 24px;
        }
      }

      & .faq-tab-container {
        margin-top: -40px;
        .answer-section {
          padding-left: 6%;
          padding-right: 6%;
          justify-content: center;
          align-items: center;
        }

        & .tab-buttons {
          padding-left: 6%;
          padding-right: 6%;

          & button {
            &.active-btn {
              background: linear-gradient(
                91deg,
                #00246a -2.45%,
                #2199ef 97.01%
              );
            }
          }
        }
      }
    }
  }

  .page-container {
    &.faq {
      padding-top: 0px;
      padding-right: 0px;
      padding-left: 0px;
    }
  }

  .contact-us-section {
    & .content {
      margin-top: 70px;
      p {
        margin-top: 0px;
        color: #fff;
        font-size: 18px;
        font-weight: 400;
        font-family: Poppins;
      }
    }
  }

  .responsible-lending-section {
    & .application-process-card {
      width: 350px;

      & .content {
        width: 320px;

        p {
          margin-top: 0;
          font-size: 14px;
          font-family: Poppins;
        }
      }
    }
  }

  .page-container {
    &.responsible-lending-page {
      padding: 0px;
      margin-top: 40px;
      text-align: center;
    }
  }

  .responsible-lending-section {
    .content {
      p {
        margin-top: 32px;
        color: #fff;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 0;
        font-family: Poppins;
      }
    }
  }

  .application-process-card {
    display: block;
    justify-content: center;
    align-items: center;
    padding: 0px;
    border-radius: 24px;
    background: linear-gradient(140deg, #5e7784 5.94%, #151b1e 90.73%);
    color: #ffffff;

    .inmageHome {
      height: 150px;
    }

    .content {
      width: fit-content;
      margin: 0px;
      margin-bottom: 20px;

      h6 {
        font-size: 22px;
        font-weight: 500;
        margin-bottom: 16px;
        font-family: Poppins;
      }

      p {
        font-size: 16px;
        margin-bottom: 0px;
        font-weight: 300;
        font-family: Poppins;
        padding: 10px;
      }
    }
  }

  .application-process-section {
    height: fit-content;

    .heading {
      font-family: Poppins;
      /* display: none; */
    }
  }

  .px-4 {
    margin-top: 20px;
  }

  .productsDiv {
    margin: 0px -20px 0px 0px;
    padding: 0px 20px;
    align-items: center;
    justify-content: center;
  }
}
.productsDivBtn {
  display: flex;
  justify-content: center;
}

.img-container {
  display: flex;
  justify-content: center;
}

h5 {
  margin-top: 12px;
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 16px;
  margin-left: 10px;
  font-family: Poppins;
  color: #ffffff;
}

h1 {
  color: #1b9bdc;
  text-align: center;
  margin-top: 12px;
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 16px;
  font-family: Poppins;
}

h2 {
  font-size: 20px;
  font-family: Poppins;
}

h3 {
  font-size: 20px;
}

@media only screen and (max-width: 1160px) {
  .products-hero-section,
  .responsible-lending-section,
  .contact-us-section,
  .faq-section {
    .btn {
      text-align: center;
    }
  }

  .page-container {
    &.about-us {
      padding-right: 60px;
      padding-left: 60px;
    }
  }

  .about-us-container {
    & .about-points-container {
      & .each-point {
        & .application-process-card {
          height: 200px !important;
          & .content {
            p {
              padding: 0%;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

td,
th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.aboutus-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: space-between;
  border-radius: 34px;
  background: linear-gradient(140deg, #5e7784 5.94%, #2a363b 90.73%);
  color: #ffffff;
  margin-bottom: 40px;
  width: 10px;
}

/* contact us renewd styles */

/* .page-container {
  display: flex;
  justify-content: center;
  align-items: center;
    flex-wrap: wrap;
} */

.mainDivAtContact {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 30px 0px 0px -10px;
}

.mainHeaderatContactSection {
  background: linear-gradient(
    91deg,
    #1b9bdc -2.45%,
    #76d1ff 51.77%,
    #1b9bdc 97.01%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 34px;
  font-weight: 600;
  text-align: left;
  font-family: Poppins;
}

.subHeaderAtContactSection {
  text-align: left;
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 300;
  font-family: Poppins;
  color: white;
}

.imageInContact {
  float: right;
  margin-left: 0px;
  height: 300px;
  /* max-width: 30%;
  width: 300px; */
}

.hangout {
  float: left;
  margin-left: 20px;
  max-width: 30%;
  margin-left: 0;
  height: 60px;
}

.textContent {
  max-width: 60%;
}

.form-container {
  max-width: 600px;
  align-items: left;
  margin-left: 10%;
}

.form-group {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}

.form-group:last-child {
  margin-bottom: 48px;
}

.form-control {
  padding: 10px;
  padding-left: 16px;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.753);
  /* border-radius: 8px; */
  /* font-size: 18px; */
  height: 48px;
  /* border: 1px solid #fff; */
}

.form-control::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.986);
}

.form-control::placeholder {
  color: rgba(255, 255, 255, 0.753);
}

.contactUsDropdown {
  width: 100%;
  padding: 10px;

  /* width:201px; */
  /* padding-left: 16px; */
  margin: 0px 26px 10px 0px;
  background-color: transparent;
  color: rgba(255, 255, 255, 0.753);
  border: 1px solid #fff;
  border-radius: 8px;
}

.contact-submit-button {
  padding: 8px 40px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: Poppins;
}

.contact-submit-button:hover {
  background-color: #637f9e;
  opacity: 1;
}

.row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
}

.form-container,
.aboutus-card {
  flex: 1;
  min-width: 300px;
  margin: 10px;
}

.form-container .form-group {
  margin-bottom: 15px;
}

.form-container .form-control {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}

.emailLink .contact-us-font {
  display: none;
  font-size: 16px;
  line-height: 1.5;
  margin-right: -100px;
}
.vl {
  border-left: 1px solid rgb(248, 248, 248);
  height: 270px;
}
.contactUsMain {
  text-align: left;
  margin-left: 4%;
  font-size: 2rem;
  margin-top: -10vw;
}

@media (max-width: 760px) {
  .contact-submit-button {
    margin-left: 30%;
  }
  .contactUsMain {
    text-align: center;
    margin-right: 7vw;
  }
  .contactUsDropdown {
    margin: 0px 0px 10px 0px;
  }
  .mainDivAtContact {
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 60px;
  }

  .textContent {
    max-width: 100%;
  }

  .imageInContact {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .row-container {
    flex-direction: column;
    align-content: space-around;
  }

  .form-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: stretch;
  }
  .emailLink {
    display: flex;
    margin-left: 16vw;
    margin-top: 10vh;
  }
  .vl {
    display: none;
  }
  /* .top-cards-container{
        flex-direction: column;
  }  */
}

.responseText {
  color: #ffffff;
  text-align: center;
}

/*scroll-bar stylings*/
::-webkit-scrollbar {
  width: 9px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(128, 128, 128, 0);
  background-color: #2a363b;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.116);
  border-radius: 10px;
  border-left: 2px solid #2a363b;
  border-right: 2px solid #2a363b;
}
::-webkit-scrollbar-thumb:hover {
  background: #ffffff86;
}

/*inline-stylings*/
.applicationProcessCard {
  font-size: 16px;
  font-weight: 300;
}
.dropdownUnderLine {
  margin: 0px;
}
.nav-logo-creditmitra {
  width: 180;
}
.headerMainDiv {
  display: flex;
}
.headerSecondDiv {
  color: white;
  display: flex;
}
.productDropDownBg {
  color: white;
}
.buttonInLocation {
  color: #fff;
  text-decoration: none;
}

.contactusCheckBox {
  color: #fff;
}
.contactusCheckBoxLegal {
  text-decoration: none;
}
.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.contactusCheckBox {
  margin-left: 10px;
  font-size: 13px;
}

.contactusCheckBoxLegal {
  margin-left: 5px;
}


/* easy loan stylings */
.easyloan-container{
overflow: hidden;
/* padding-right: 20px;
padding-left: 20px; */
}
.easy-loan-main-logo{
  align-self: start;
  margin-top: 20px;
  margin-left: 20px;
}
.pInEasyLoan{
  text-align: center;
  font-size: 12px;
  margin-bottom: 0px;
  font-weight: 300;
  font-family: Poppins;
  color: #fff;
  width: 90%;
  margin: auto;
}
.top-cards-container {
  display: flex; 
  justify-content: center;
}

.top-cards {
  text-align: center;
  border: 1px solid #00000061;
  border-radius: 10px;
  /* background:linear-gradient(140deg, #59666e 5.94%, #a0b3bd3b 90.73%); */
  background-color: #4c606bac;
  box-shadow: 10px 20px 50px 10px rgb(0 0 0);
  margin: 10px;
}
.bottom-cards{
  text-align: center;
  border: 1px solid #00000061;
  border-radius: 10px;
  background:linear-gradient(140deg, #5e7784 5.94%, #151b1e3b 90.73%);
  box-shadow: 10px 20px 50px 10px rgb(0 0 0);
  margin: 10px;
}
.top-card-text{
  color: #fff;
  font-size: 10px;
  font-family: Poppins;
  margin: 10px;
}
.top-card-img{
  height: 50px;
  margin: 10px;
}
.line {
  border-left: 1px solid rgb(248, 248, 248);
  height: 40px;
}
.banner-container{
  display: flex;
  justify-content: space-evenly;
  background: linear-gradient(140deg, #5e7784 5.94%, #151b1e3b 90.73%);
  padding: 50px;
  flex-wrap: wrap
}
.banner-elements{
  display: flex;
  align-items: center;
  padding: 20px;
}
.category-container {
  width: 100%; 
}
.fieldName{
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  width: 100%;
  margin: 5px;
}
.easyloan-form-container{
  display: flex;
  justify-content: center;
}
.el-input{
  display: inline;
  font-size: 12px;
  height: 35px;;
}

  .list-row-el {
    display: flex;
    flex-wrap: wrap;
    justify-content:center
}
.hiring-button-el{
  background: transparent;
  transition: #1da1e8 0.3s ease;
  color: #ffffff;
  border: 1px solid #7ED2FF;
  margin: 5px;
  border-radius: 17px;
}

/* .hiring-button-el.selected {
  background-color: rgb(75, 75, 144);
} */
.el-button{
  display: flex;
  justify-content: center
}

.el-form{
  border: 1px solid #151b1e;
  padding: 60px;
  background: linear-gradient(140deg, #5e7784 5.94%, #151b1e3b 90.73%);
  border-radius: 20px;
  box-shadow: 10px 20px 50px 10px rgb(0 0 0);
}
