@media (min-width: 768px) and (max-width: 1124px) {
  .emi-details {
    span {
      font-size: 1.05rem;
    }
    .emi-left {
      flex: 4.4;
    }
    .emi-right {
      flex: 2;
    }
  }
  .colors {
    .legendText {
      font-size: 0.99rem;
      .legendValue {
        display: none;
      }
    }
  }
  .aboutEmi {
    text-align: justify;
  }

  .input-section {
    margin-bottom: 3% !important;
    margin-top: 3%;
    margin-left: 20px;
    margin-right: 20px;
  }
  .pie {
    width: 60%;
  }
  .caclulator-card {
    padding-bottom: 1%;
  }
}
