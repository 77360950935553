
.h5 {
  font-size: 12px;
  display: none;
}
.heading {
  background: linear-gradient(
    91deg,
    #1b9bdc -2.45%,
    #76d1ff 51.77%,
    #1b9bdc 97.01%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  font-family: Poppins;
}
