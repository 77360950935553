.page404-container {
  min-height: 100vh;
}

.page404 {
  margin-top: 90px;
}

.gif404-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  flex-direction: column;
  align-items: center;
}

.gif404 {
  width: 40%;
}

.para404 {
  font-size: 1.5rem;
  text-align: center;
  color: azure;
  font-family: Poppins, sans-serif;
  padding-bottom: 20px;
  /* margin-bottom: 20px; */
}

.bottomPara404 {
  border-bottom: 2px solid rgba(25, 156, 219, 0.5);
  display: inline-block;
  padding-bottom: 5px;
}

.links404 {
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
  color: azure;
  .space404 {
    margin-left: 1%;
    margin-right: 1%;
  }
}

.links404 a {
  /* Targeting <a> tags directly for react-router-dom Link components */
  text-decoration: none; /* Removes the underline */
  color: #199cdb;
  /* Ensures the link color matches the parent if needed */
}

@media screen and (max-width: 768px) {
  .content404 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .gif404 {
      width: 80%;
    }
  }
  .para404 {
    font-size: 1.2rem;
  }

  .links404 {
    font-size: 1.1rem;
    padding-left: 1%;
    padding-right: 1%;
  }
}
