/* Container Styles */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

/* Heading Styles */
.content-heading {
    background: linear-gradient(
        91deg,
        #1b9bdc -2.45%,
        #76d1ff 51.77%,
        #1b9bdc 97.01%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 2.5rem;
    font-weight: 700;
    text-align: center;
    font-family: Poppins, sans-serif;
    margin-top: 50px;
}

.subHeading {
    text-align: center;
    font-family: Poppins, sans-serif;
    font-size: 1.2rem;
    color: #ffffff;
    width: 80%;
    margin: 20px 0;
}

.hiring-heading {
    font-size: 2rem;
    margin: 20px 0;
}

/* Button Styles */
.hiring-button {
    background: transparent;
    transition: background-color 0.3s ease;
    color: #ffffff;
    border: 1px solid #7ED2FF;
    margin: 6px;
    border-radius: 17px;
    padding: 5px 10px;
}

.hiring-button.selected {
    background-color: blue;
}

.list-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

/* Job Posts Styles */
.job-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
}

.job-card {
    background-color:transparent;
    max-width: 100vw;
    transition: transform 0.2s;
    width: 60vw;
}
.job-card:hover {
    transform: translateY(-10px);
}

.designation {
    color: #ffffff;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.head-line {
    color: #f8f9fa;
    font-size: 1rem;
    margin-bottom: 15px;
    font-family: Poppins;
}

.modes {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.mode {
    color: #ffffff;
    background-color: transparent;
    border: 1px solid #7ED2FF;
    border-radius: 15px;
    font-size: 0.9rem;
    padding: 5px 10px;
}

hr {
    border: 1px solid #7ED2FF;
    width: 100%;
}

.like-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
align-items: flex-start;
    margin-top: -40px;
}

.like {
    height: 30px;
    width: 30px;
    margin-right: 10px;
}

.like-text {
    font-size: 1rem;
    color: #ffffff;
}
.hiring-apply-now{
   border: 1px solid #a9e2ff;
   border-radius: 4px;
   background: #198bc7;
   color: #ffffff;
   font-size: 1rem;
   font-family: Poppins;
   padding: 4px 20px;
   margin-bottom: 20px;
}

@media(max-width:768px) {
    .like-container {
        margin-top:0px;
    }
}

.JD-container{
    padding-left: 100px;
    padding-right: 100px;
    margin-top: 100px
}
h2{
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 15px;
    font-family: Poppins;
    color: #fff;
}
.JobDescription{
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 10px;
    font-family: Poppins;
    color: #fff;
}
.location{
    color: #ffffff;
    font-size: 1rem;
    font-weight:light;
    margin-bottom: 10px;
    text-align: center;
    font-family: Poppins;
}
.card{
    background-clip: text;
}


/*MODAL stylings*/
.text-field{
    color: #ffffff;
    font-size: 1rem;
    font-weight:light;
    font-family: Poppins;
    margin: 10px  
}
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.32);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
  }
  
  .modal-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background: linear-gradient(140deg, #5e7784 5.94%, #151b1e 90.73%);
    border: 3px solid #198bc7;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: 10px;
    outline: none;
    box-shadow:0 4px 50px ALICEBLUE;
    width:-webkit-fill-available;
    overflow: hidden;
  }
  textarea.form-control {
    resize: none;
    height: 100px;
  }
  .fileInput{
    color: #fff;
    width: 40%;
  }
  #pdfInput{
    color: #fff;
  }
  .form-in-hiring{
    margin-top: 10px;
  }
  .ModalContainer{
    display: flex;
    justify-content: space-around;
  }


.job-application-container{

}

.card-img-top{
    height: 16vh;
    width: 15vw;
}
.section-red {
    display: flex;
    background: repeating-radial-gradient(circle, rgb(92, 95, 105) 0%,
    rgba(2, 0, 36, 1) 30%,
    rgb(48, 109, 142) 100%);
    padding: 20px;
  }



  /* App.css or Background.css */

.background {
  background: linear-gradient(to bottom right, #0D47A1, #000000, #000000, #d455d0);
  height: 100vh;
  position: relative;
  overflow: hidden; 
}
.dots {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:
  radial-gradient(circle , rgb(0, 0, 0) 5px, transparent 1px);
  background-position: 0 0, 100% 0, 0 100%, 100% 100%;
  background-size: 50px 50px;
}





  .section-blue {
    display: flex;
      background: repeating-radial-gradient(circle, rgb(92, 95, 105) 0%,
      rgba(2, 0, 36, 1) 100%,
      rgb(48, 109, 142) 10%);
          padding: 20px;
  }
  
  .section-green {
    display: flex;
    background: repeating-radial-gradient(circle, rgb(92, 95, 105) 0%,
    rgba(2, 0, 36, 1) 30%,
    rgb(48, 109, 142) 100%);
        padding: 20px;
  }
  .bubble {
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  
  .process-card {
    flex-grow: 1;
    border: 1px solid;
  }

  .card-title{
    margin:10px
  }
  
  @media (max-width: 768px) {
    .bubble, .process-card {
      width: 100%;
    }
  }
  


  @keyframes typing {
    from { width: 0; }
    to { width: 100%; }
  }
  
  .auto-type-text {
    overflow: hidden; 
    border-right: .15em solid #5e7784;
    white-space: nowrap; 
    margin: 0 auto;
    animation: typing 3s steps(40, end), blink-caret .75s step-end infinite;
  }
  .align-items-center{
    display: flex;
    width: 80%;
  }
  
  @keyframes blink-caret {
    from, to { border-color: transparent; }
    50% { border-color: rgb(172, 179, 183) }
  }
  .process-card{
  display: flex;
  flex-direction: column;
  padding: 0px 10px 20px 20px;
  border-radius: 24px;
  background: linear-gradient(140deg, #5e7784 5.94%, #151b1e 90.73%);
  color: #ffffff;
  }
  .justify-content-around{
    display:flex;
  }


  /* feedback */
  /* FeedbackList.css */

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.feedback-card {
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px;
  padding: 20px;
  animation: slideIn 1s ease-in-out, bounce 2s infinite ease-in-out;
}

.feedback-card img {
  border-radius: 50%;
  margin-right: 20px;
  width: 60px;
  height: 60px;
}

.feedback-card div {
  display: flex;
  flex-direction: column;
}

.feedback-card h4 {
  margin: 0;
  font-size: 1.2em;
}

.feedback-card p {
  margin: 5px 0 0;
}


/* FooterAnimations.css */

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes popIn {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.footer-globe {
  animation: fadeIn 1s ease-in-out;
}

.footer-content {
  animation: fadeIn 1.5s ease-in-out;
}

.dropdownFooter-item {
  animation: popIn 0.5s ease-in-out;
}
.footer-section-JA {
  padding: 154px 20px 40px;
  color: #ffffff;
  position: relative;
  z-index: 999;

  .footer-globe {
    position: absolute;
    height: 450px;
    z-index: 400;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
  }
  .footer-data {
    background-color: red;
  }

  .footer-content-JA {
    z-index: 1000;
    /* background: rgba(255, 255, 255, 0.1); */
    -webkit-backdrop-filter: blur(70px);
    backdrop-filter: blur(150px);
    padding: 72px 32px 24px;
    position: relative;

    .footer-container {
      display: flex;
      gap: 0px;

      .navigation-container {
        display: flex;
        flex-wrap: wrap;
        /* grid-gap:0%; */
        justify-content: space-between;
        /* margin-top: 15px; */

        h6 {
          color: #fff;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 16px;
          width: auto;
          padding-top: 15px;
          font-family: Poppins;
        }

        ul {
          list-style: none;
          padding: 0;
          margin-bottom: 0;

          li {
            margin-bottom: 18px;

            &:last-child {
              margin-bottom: 0;
            }

            a {
              color: #fff !important;
              font-size: 16px;
              text-decoration: none;
              font-family: Poppins;

              /* &:hover {
                } */
            }
          }
        }
      }
    }

    .copyright-text {
      color: rgba(255, 255, 255, 0.6);
      font-size: 14px;
      margin-bottom: 0;
      text-align: center;
      margin-top:12px;
      font-family: Poppins;
    }
  }
}
.pinFooter{
  font-family: Poppins;
}