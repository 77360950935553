@media (min-width: 769px) and (max-width: 1160px) {
  .footer-content {
    flex-direction: column;
    font-size: 14px;
  }

  .footer-content .navigation-container {
    flex-direction: column;
  }
}
